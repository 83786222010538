<template>
  <v-container class="bg-glass-effect px-3 py-0" fluid>
    <v-row class="py-0">
      <v-col>
        <img
            @click="onClickBack()"
            class="arrow-position"
            src="/img/icons/common/back-arrow.svg"
        />
      </v-col>
      <v-col cols="12">
        <v-stepper class="stepper-layout" v-model="stepCount">
          <v-stepper-items style="height: 87vh;">
            <v-stepper-content class="py-0 my-0" step="1">
              <v-row class="py-0 my-0" style="height: 90vh;">
                <!--start-->
                <v-col class="py-0 my-0" align-self="start" cols="12">
                  <v-row class="py-0 pt-5 my-0">
                    <v-col cols="12">
                      <h1 style="color: #4A499B;">Conta</h1>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="pb-0 mb-0 caption" style="color: rgba(63, 63, 63, 0.4);" cols="12">
                      <span>E-mail</span>
                    </v-col>
                    <v-col class="pt-0 mt-0" cols="12">
                      <span class="text-truncate" style="color: #3F3F3F">{{ this.user.email }}</span>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="12">
                      <v-text-field ref="textFieldPhone" label="Telefone" v-model="user.phoneNumber" v-mask="'(##) #####-####'" :disabled="isPhoneReadyOnly" autofocus>
                        <template v-slot:append>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-img v-on="on" :src="editTextAppendIcon"></v-img>
                            </template>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0 my-0" cols="12">
                      <v-btn
                          v-if="isValidateButtonEnabled"
                          class="mt-5 py-4 btn" rounded small
                          color="primary"
                          @click="onClickValidatePhoneNumber()"
                      >Validar número
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <!--end-->
                <v-col align-self="end" cols="12">
                  <v-row>
                    <v-col v-if="isEditingPhone" cols="12">
                      <v-btn class="py-7 mb-4 btn btn-great" block rounded large color="primary" @click="onClickUpdatePhone()" :disabled="!isUpdateButtonEnabled">Atualizar</v-btn>
                    </v-col>
                    <v-col v-else class="my-0 py-0" cols="12">
                      <v-row class="px-3" justify="end">
                        <v-btn v-if="isVisibleFloatingButton" color="pink" fab right fixed bottom @click="onClickEdit()">
                          <v-img max-width="34" max-height="34" :src="editButtonIcon"></v-img>
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-row class="py-0 my-0" style="height: 87vh;">
                <!--start-->
                <v-col class="py-0 my-0" align-self="start" cols="12">
                  <v-row class="py-0 pt-2 my-0">
                    <v-col cols="12">
                      <h1 class="pb-3" style="color: #4A499B;">Validar telefone</h1>
                      <span>Insira o seu código que enviamos por SMS para o seu número.</span>
                    </v-col>
                  </v-row>

                  <v-row class="pt-10">
                    <v-col class="pb-0 mb-0" cols="2">
                      <v-text-field ref="textFieldValidationCode1" class="centered-input" v-model="validationCode.digit1" v-mask="'#'" autofocus
                                    :readonly="isValidationCodeReadOnly" @keyup.backspace="onClickBackspace(1)"></v-text-field>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="2">
                      <v-text-field ref="textFieldValidationCode2" class="centered-input" v-model="validationCode.digit2" v-mask="'#'"
                                    :readonly="isValidationCodeReadOnly" @keyup.backspace="onClickBackspace(2)"></v-text-field>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="2">
                      <v-text-field ref="textFieldValidationCode3" class="centered-input" v-model="validationCode.digit3" v-mask="'#'"
                                    :readonly="isValidationCodeReadOnly" @keyup.backspace="onClickBackspace(3)"></v-text-field>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="2">
                      <v-text-field ref="textFieldValidationCode4" class="centered-input" v-model="validationCode.digit4" v-mask="'#'"
                                    :readonly="isValidationCodeReadOnly" @keyup.backspace="onClickBackspace(4)"></v-text-field>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="2">
                      <v-text-field ref="textFieldValidationCode5" class="centered-input" v-model="validationCode.digit5" v-mask="'#'"
                                    :readonly="isValidationCodeReadOnly" @keyup.backspace="onClickBackspace(5)"></v-text-field>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="2">
                      <v-text-field ref="textFieldValidationCode6" class="centered-input" v-model="validationCode.digit6" v-mask="'#'"
                                    :readonly="isValidationCodeReadOnly" @keyup.backspace="onClickBackspace(6)"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="my-0 pb-0 pt-5" cols="12">
                      <v-btn class="btn py-4" rounded small color="primary" :disabled="isValidationCodeReadOnly" @click="onClickNotReceived()">
                        Não
                        <span style="text-transform: lowercase">&nbsp;recebi o </span>
                        <span style="text-transform: uppercase">&nbsp;sms</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <!--end-->
                <v-col align-self="end" cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-btn class="py-7 mb-5 btn btn-great" block rounded large color="primary" @click="onClickValidateCode()" :disabled="!isValidateCodeButtonEnabled">Validar</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>

          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <!--dialog-->
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="dialog.show" persistent max-width="400px">
          <v-card :style="dialog.style" rounded="lg">
            <v-row justify="center" class="ma-0 px-0 pt-4" style="height: 100%;">
              <v-col cols="11" align-self="start">
                <v-row>
                  <v-col cols="12">
                    <v-btn icon :color="dialog.colorDefault" @click="closeDialog()">
                      <v-icon large>fas fa-times</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col col="12">
                    <h2
                        class="pb-5 generic-title"
                        style="text-align: center; font-weight: bold;"
                        :style="{color: dialog.colorDefault}"
                    >Atenção</h2>
                    <p
                        class="px-5 generic-text"
                        style="text-align: center; font-size: 20px"
                        :style="{color: dialog.colorDefault}"
                    >O número de telefone não foi validado. <br/> Deseja sair mesmo assim?</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="11" align-self="center">
                <v-btn class="btn btn-great" :color="dialog.colorDefault" block x-large rounded @click="$router.go(-1)">Sim, Quero Sair</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <!--snackbar-->
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar.show = false">Fechar</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Phone from '@/services/security/Phone'
export default {
  name: "account",
  data: () => ({
    stepCount: 1,
    dialog: {
      message: '',
      show: false,
      colorDefault: 'primary',
      style: {
        color: '#292867',
        backgroundColor: '#EEEDF7',
        height: '80vh'
      }
    },
    editButtonIcon: '/img/icons/common/edit-pencil.svg',
    editTextAppendIcon: '',
    isClickBackButton: false,
    isEditingPhone: false,
    isPhoneReadyOnly: true,
    isUpdateButtonEnabled: true,
    isValidateCodeButtonEnabled: false,
    isValidationCodeReadOnly: false,
    isValidateButtonEnabled: false,
    isVisibleFloatingButton: false,
    snackbar: {
      show: false,
      message: '',
      messageInvalidCode: 'Código inválido. Verifique o SMS enviado e tente novamente.',
      messageOk: 'Enviamos um novo SMS com o código de validação para você.',
      messageError: 'Ocorreu um erro ao enviar o SMS.',
      messageSizePhoneError: 'Por favor, preencha o telefone corretamente.',
      timeout: 5000
    },
    user: null,
    validationCode: {
      digit1: null,
      digit2: null,
      digit3: null,
      digit4: null,
      digit5: null,
      digit6: null
    }
  }),
  created() {
    this._phone = new Phone();
    const storageSession = this.$auth.getSession()
    this.user = storageSession.user

    this._phone.FindAllPhonesByUserId()
        .then(response => {
          if(response.data && response.data.length > 0) {
            this.user.phoneId = response.data[0].id
            this.user.phoneStatus = response.data[0].status
            this.user.phoneDialingCode = response.data[0].dialingCode
            this.user.phoneNumber = response.data[0].phoneNumber
          } else {
            this.resetPhoneData()
          }

        }).catch(error => {
          this.resetPhoneData()
        }).finally(()=>{

          if(this.user && this.user.phoneStatus) {
            this.changeTextFieldAppendIcon()
          } else {
            this.editTextAppendIcon = ''
          }

          if(this.user && this.user.phoneDialingCode && this.user.phoneNumber) {
            this.user.phoneNumber = this.user.phoneDialingCode + this.user.phoneNumber
          }

          this.verifyValidateButtonIsEnabled()
          this.isVisibleFloatingButton = true
        })
  },
  methods:{
    cleanFieldsCode(){
      this.validationCode.digit1 = null;
      this.validationCode.digit2 = null;
      this.validationCode.digit3 = null;
      this.validationCode.digit4 = null;
      this.validationCode.digit5 = null;
      this.validationCode.digit6 = null;
    },
    changeTextFieldAppendIcon(){
      this.editTextAppendIcon = this.user.phoneStatus == 'AGUARDANDO_VERIFICACAO' ?
          '/img/icons/common/phone-check-negative.svg' : '/img/icons/common/phone-check-positive.svg'
    },
    closeDialog() {
      this.dialog.show = false
    },
    getPhoneToSave(saveMode){
      const phoneNumberUnmask = this.$util.formmasks.unmask(this.user.phoneNumber)
      const dialingCode = phoneNumberUnmask.substring(0, 2)
      const phoneNumber = phoneNumberUnmask.substring(2,this.user.phoneNumber.length)

      if(saveMode == 'SAVE'){
        return {
          dialingCode: dialingCode,
          phoneNumber: phoneNumber,
          userId: this.user.id,
        }
      } else {
        return {
          dialingCode: dialingCode,
          phoneNumber: phoneNumber,
          userId: this.user.id,
          id: this.user.phoneId,
          status: 'AGUARDANDO_VERIFICACAO'
        }
      }
    },
    onClickBack() {
      if(this.user && this.user.phoneStatus && this.user.phoneStatus == 'AGUARDANDO_VERIFICACAO') {
        this.dialog.show = true
      }else {
        this.$router.go(-1)
      }
    },
    onClickBackspace(digitPosition){
      switch (digitPosition){
        case 2:
          this.$refs.textFieldValidationCode1.focus()
          break;
        case 3:
          this.$refs.textFieldValidationCode2.focus()
          break;
        case 4:
          this.$refs.textFieldValidationCode3.focus()
          break
        case 5:
          this.$refs.textFieldValidationCode4.focus()
          break;
        case 6:
          this.$refs.textFieldValidationCode5.focus()
          break;
        default:

      }
    },
    onClickEdit() {
      this.isValidateButtonEnabled = false
      this.isPhoneReadyOnly = false
      this.isEditingPhone = true
      setTimeout(()=>{
        this.$refs.textFieldPhone.focus()
      }, 100)
    },
    onClickUpdatePhone() {
      const phoneNumberUnmask = this.$util.formmasks.unmask(this.user.phoneNumber)
      if(phoneNumberUnmask && phoneNumberUnmask.length == 11) {
        if (this.user && this.user.phoneId) {
          this.registerPhone('UPDATE')
        } else {
          this.registerPhone('SAVE')
        }
      } else {
        this.snackbar.message = this.snackbar.messageSizePhoneError
        this.snackbar.show = true
        this.$refs.textFieldPhone.focus()
      }
    },
    onClickValidatePhoneNumber() {
      this.stepCount = 2
      this._phone.ValidatePhoneById(this.user.phoneId)
          .then(response => {
          }).catch(error => {
            this.snackbar.message = this.snackbar.messageError
            this.snackbar.show  = true
          }).finally(()=>{
          })
    },
    onClickNotReceived(){
      this._phone.ValidatePhoneById(this.user.phoneId)
          .then(response => {
            this.snackbar.message = this.snackbar.messageOk
          }).catch(error => {
            this.snackbar.message = this.snackbar.messageError
          }).finally(()=>{
            this.snackbar.show  = true
          })
    },
    onClickValidateCode(){
      const code = this.validationCode.digit1 + this.validationCode.digit2 + this.validationCode.digit3
          + this.validationCode.digit4 + this.validationCode.digit5 + this.validationCode.digit6

      this.isValidationCodeReadOnly = true
      this.isValidateCodeButtonEnabled = false
      this._phone.VerifyValidationCodeIsValid(code, this.user.phoneId)
          .then(response => {
            if(response.data) {
              this.user.phoneStatus = 'VERIFICADO'
              this.verifyValidateButtonIsEnabled()
              this.changeTextFieldAppendIcon()
              this.stepCount = 1
              this.cleanFieldsCode()
            } else {
              this.$refs.textFieldValidationCode6.focus()
              this.snackbar.message = this.snackbar.messageInvalidCode
              this.snackbar.show = true
            }
          }).catch(error => {
          }).finally(()=>{
            this.isValidationCodeReadOnly = false
            this.isValidateCodeButtonEnabled = true
          })
    },
    resetPhoneData(){
      this.user.phoneId = this.user.phoneStatus = this.user.phoneDialingCode = this.user.phoneNumber = null
    },
    registerPhone(saveMode) {
      this.isUpdateButtonEnabled = false

      const phoneToSave = this.getPhoneToSave(saveMode)
      let servicePhone = null

      if(saveMode == 'SAVE'){
        servicePhone = this._phone.Save(phoneToSave)
      }else{
        servicePhone = this._phone.Update(phoneToSave)
      }

      servicePhone
          .then(response => {
            if(response.data) {
              this.user.phoneStatus = response.data.status
              this.user.phoneDialingCode = response.data.dialingCode
              this.user.phoneId = response.data.id
              this.changeTextFieldAppendIcon()
              this.verifyValidateButtonIsEnabled()
            }
          }).catch(error => {
          }).finally(()=>{
            this.isPhoneReadyOnly = true
            this.isEditingPhone = false
            this.isUpdateButtonEnabled = true
          })
    },
    verifyValidateButtonIsEnabled() {
      if(this.user && this.user.phoneStatus == 'AGUARDANDO_VERIFICACAO' &&
          this.user.phoneDialingCode && this.user.phoneNumber) {
        this.isValidateButtonEnabled = true
      } else {
        this.isValidateButtonEnabled = false
      }
    },
    verifyValidationCodeNotBlank(){
      if(this.validationCode.digit1 != '' && this.validationCode.digit1 != null && this.validationCode.digit2 != '' && this.validationCode.digit2 != null
        && this.validationCode.digit3 != '' && this.validationCode.digit3 != null && this.validationCode.digit4 != '' && this.validationCode.digit4 != null
        && this.validationCode.digit5 != '' && this.validationCode.digit5 != null && this.validationCode.digit6 != '' && this.validationCode.digit6 != null) {
        this.isValidateCodeButtonEnabled = true
      } else {
        this.isValidateCodeButtonEnabled = false
      }
    }
  },
  watch: {
    'validationCode.digit1' () {
      if(this.validationCode.digit1.length > 0) {
        this.$refs.textFieldValidationCode2.focus()
      }
      this.verifyValidationCodeNotBlank()
    },
    'validationCode.digit2' () {
      if(this.validationCode.digit2.length > 0) {
        this.$refs.textFieldValidationCode3.focus()
      }
      this.verifyValidationCodeNotBlank()
    },
    'validationCode.digit3' () {
      if(this.validationCode.digit3.length > 0) {
        this.$refs.textFieldValidationCode4.focus()
      }
      this.verifyValidationCodeNotBlank()
    },
    'validationCode.digit4' () {
      if(this.validationCode.digit4.length > 0) {
        this.$refs.textFieldValidationCode5.focus()
      }
      this.verifyValidationCodeNotBlank()
    },
    'validationCode.digit5' () {
      if(this.validationCode.digit5.length > 0) {
        this.$refs.textFieldValidationCode6.focus()
      }
      this.verifyValidationCodeNotBlank()
    },
    'validationCode.digit6' () {
      this.verifyValidationCodeNotBlank()
    }
  }
}
</script>

<style scoped>

/*background effect blur*/
.bg-glass-effect{
  background: rgba(255,255,255,1.0) !important;
  mix-blend-mode: normal !important;
  backdrop-filter: blur(100px) !important;
  height: 100vh;
}

.stepper-layout {
  box-shadow: none !important;
  background-color: transparent !important;
}

.btn {
  text-transform: capitalize;
  font-weight: bold;
  font-family: Open Sans;
  font-size: 15px;
}

.btn-great {
  font-size: 18px;
}

.arrow-position {
  width: 28px;
  height: 23px;
  position: absolute;
  z-index: 10000;
  left: 25px;
  top: 15px;
}

.centered-input >>> input {
  text-align: center;
}

</style>


